import { Dispatch, SetStateAction, useState } from "react";
import { useBetween } from "use-between";

import { languageType, translations } from "@translations/resources";

interface IUseTranslationReturnType {
  chosenLang: string;
  setLanguage: Dispatch<SetStateAction<languageType>>;
  t: (translationKey: string) => string;
}

const useTranslationHook = (): IUseTranslationReturnType => {
  const [chosenLang, setLanguage] = useState<languageType>("en");

  const t = (translationKey: string): string => {
    const currentTranslation = translations[chosenLang];

    return currentTranslation[`${translationKey}`];
  };

  return { chosenLang, setLanguage, t };
};

export const useTranslation = (): IUseTranslationReturnType => {
  return useBetween(useTranslationHook);
};
