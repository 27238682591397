import { styled, StyledComponentProps } from "@mui/material";
import Input from "@mui/material/Input";
import React, { useEffect, useRef, useState } from "react";

import { COLORS } from "../../../constants/enums/COLORS";
import { useConvertArabicToArabic } from "../../../hooks/useConvertArabicToArabic";

interface IPinInputProps {
  pin: Array<number | undefined>;
  onPinChanged(pin: Array<number | undefined>): void;
  pinLength: number;
  error?: boolean;
  disabled?: boolean;
}

interface StyledInputProps extends StyledComponentProps {
  isEmptyAndVisited: boolean;
  filled: boolean;
  error: boolean;
}

export const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => !["isEmptyAndVisited", "filled", "error"].includes(prop as string),
})<StyledInputProps>(({ theme, isEmptyAndVisited, error, filled }) => ({
  background: "transparent",
  color: theme.palette?.primary?.dark || COLORS.PRIMARY_DARK,
  fontWeight: 500,
  height: "68px",
  fontSize: "1.4rem",
  padding: "16px",
  width: "49px",
  minWidth: "49px",
  textAlign: "center",
  marginRight: "7px",
  position: "relative",
  "&::after": {
    content: `""`,
    position: "absolute",
    bottom: "0",
    height: "2px",
    left: "0",
    borderRadius: "12px",
    width: "100%",
    backgroundColor: theme.palette.greyscale.grey08 || COLORS.GREY_08,
  },
  "&.Mui-focused::after": {
    backgroundColor: `${theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN}`,
  },
  "& .MuiInput-input": {
    textAlign: "center",
    fontFamily: "Verdana, sans-serif",
  },
  ...(filled && {
    "&::after": {
      content: `""`,
      position: "absolute",
      bottom: "0",
      height: "2px",
      left: "0",
      borderRadius: "12px",
      width: "100%",
      backgroundColor: theme.palette.greyscale.grey32 || COLORS.GREY_32,
    },
  }),
  ...((isEmptyAndVisited || error) && {
    "&::after": {
      content: `""`,
      position: "absolute",
      bottom: "0",
      height: "2px",
      left: "0",
      borderRadius: "12px",
      width: "100%",
      backgroundColor: `${theme.palette?.dark?.negative || COLORS.DARK_NEGATIVE}`,
    },
  }),
}));

export const StyledDot = styled(
  "div",
  {},
)<{ hidden: boolean }>(({ hidden }) => ({
  fontSize: "14px",
  position: "absolute",
  height: "20px",
  width: "20px",
  visibility: hidden ? "hidden" : "initial",
  left: "20px",
  top: "24px",
  backgroundColor: "white",
  zIndex: 1,
  "&::after": {
    content: '"\\\u25CF"',
  },
}));

const PIN_MIN_VALUE = 0;
const PIN_MAX_VALUE = 9;
const BACKSPACE_KEY = "Backspace";

export const PinInput: React.FC<IPinInputProps> = ({
  pinLength,
  pin,
  onPinChanged,
  disabled = false,
  error = false,
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const [visitedIndexes, setVisitedIndexes] = useState(new Array(pinLength).fill(false));
  const [emptyInputs, setEmptyInputs] = useState(new Array(pinLength).fill(false));
  const [isReadonly, setReadonly] = useState(true);
  const { convertArabicToArabic, valueIsNumber } = useConvertArabicToArabic();

  const markInvalidInputs = () => {
    const newEmptyInputs = [...emptyInputs];
    inputRefs.current.forEach((inputRef, index) => {
      newEmptyInputs[index] = !!(inputRef.value === "" && visitedIndexes[index]);
    });
    setEmptyInputs(newEmptyInputs);
  };

  useEffect(() => markInvalidInputs(), [pin]);

  // Blur autofocused first pin
  // It's required for showing keyboard on iPhone device on pin click
  useEffect(() => {
    const ref = inputRefs.current[0];
    if (ref) {
      ref.blur();
    }
  }, []);

  const changePinFocus = (pinIndex: number) => {
    const ref = inputRefs.current[pinIndex];
    if (ref) {
      ref.focus();
    }
  };

  // const onChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
  const onChange = (index: number) => (rawValue: string) => {
    // const rawValue = event.target.value;
    const normalizedValue = rawValue.length > 1 ? rawValue.slice(1, 2) : rawValue;
    const value = convertArabicToArabic(normalizedValue);
    const pinNumber = Number(value.trim());
    if (isNaN(pinNumber) || value.length === 0) {
      return;
    }

    if (pinNumber >= PIN_MIN_VALUE && pinNumber <= PIN_MAX_VALUE) {
      const newPin = [...pin];

      newPin[index] = pinNumber;
      onPinChanged(newPin);

      if (index < pinLength - 1) {
        changePinFocus(index + 1);
      }
      const newVisitedIndexes = [...visitedIndexes];
      newVisitedIndexes[index] = true;
      setVisitedIndexes(newVisitedIndexes);
    }
  };

  const onKeyDown = (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyboardKeyCode = event.key;

    const keyIsNumber = valueIsNumber(keyboardKeyCode);

    if (keyIsNumber) {
      return onChange(index)(keyboardKeyCode);
    }

    if (keyboardKeyCode !== BACKSPACE_KEY) {
      return;
    }
    if (pin[index] === undefined) {
      changePinFocus(index - 1);
    } else {
      const newPin = [...pin];

      newPin[index] = undefined;
      onPinChanged(newPin);
    }
  };

  return (
    <>
      {Array.from({ length: pinLength }, (_, index) => (
        <div style={{ position: "relative" }}>
          <StyledDot hidden={pin[index] === undefined} onClick={() => changePinFocus(index)}></StyledDot>

          <StyledInput
            key={index}
            readOnly={isReadonly}
            inputRef={(el: HTMLInputElement) => {
              if (el) {
                inputRefs.current[index] = el;
              }
            }}
            autoFocus={index === 0}
            onFocus={() => setReadonly(false)}
            inputProps={{ maxLength: 2 }}
            onKeyDown={onKeyDown(index)}
            value={pin[index] !== undefined ? pin[index] : ""}
            type={"number"}
            filled={!!pin[index] || pin[index] === 0}
            error={error}
            isEmptyAndVisited={!!emptyInputs[index]}
            disabled={disabled}
          />
        </div>
      ))}
    </>
  );
};
