import styled from "@mui/material/styles/styled";

import { Typography } from "../../ui-kit/src/components/Typography";
import { useTranslation } from "../../utils/hooks/useTranslation";
interface IPay99ButtonProps {
  onButtonClick: () => void;
}

const StyledButton = styled("button")(({ theme }) => ({
  padding: "22px 24px",
  backgroundColor: theme.palette.greyscale.white,
  width: "100%",
  border: "unset",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "&:hover": {
    cursor: "pointer",
  },
  "& .MuiTypography-root": {
    fontWeight: theme.direction === "rtl" ? 600 : "bold",
  },
  [theme.breakpoints.down("sm")]: {
    width: "fit-content",
    flexDirection: "column-reverse",
    "&>span": {
      marginTop: "8px",
    },
  },
}));

export const Pay99Button: React.FC<IPay99ButtonProps> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <StyledButton onClick={onButtonClick}>
      <Typography component={"span"} fontSize={"16px"}>
        {t("payLaterWith99")}
      </Typography>
      <img src={`${window.REACT_APP_ASSETS_SRC}/jak_button_icon.svg`} alt={"Jak icon"} />
    </StyledButton>
  );
};
