// To avoid a case when any key can be in prototype
const numbersMap: Record<string, string> = Object.assign(Object.create(null), {
  "٠": "0",
  "١": "1",
  "٢": "2",
  "٣": "3",
  "٤": "4",
  "٥": "5",
  "٦": "6",
  "٧": "7",
  "٨": "8",
  "٩": "9",
  "0": "0",
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
});

interface IUseConvertArabicToArabic {
  valueIsNumber: (value: string) => boolean;
  convertArabicToArabic: (value: string) => string;
}

export const useConvertArabicToArabic = (): IUseConvertArabicToArabic => {
  const valueIsNumber = (value: string) => {
    const valueContainsOnlyNumbers = value.split("").every((symbol) => numbersMap[symbol]);
    return valueContainsOnlyNumbers;
  };

  const convertArabicToArabic = (value: string) => {
    const convertedValue = value.split("").reduce((acc, symbol) => {
      const nextChar = numbersMap[symbol] ? numbersMap[symbol] : symbol;
      return acc + nextChar;
    }, "");
    return convertedValue;
  };

  return { valueIsNumber, convertArabicToArabic };
};
