import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/system/ThemeProvider";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";

import { Dialog } from "@components/Dialog";
import { Pay99Button } from "@components/Pay99Button";
import { languageType } from "@translations/resources";

import { theme } from "./ui-kit/src/theme";
import { useDialog } from "./utils/hooks/useDialog";
import { useTranslation } from "./utils/hooks/useTranslation";

interface IAppProps {
  language: languageType;
  inject_meta_tags?: string;
  default_on_pay_handler?: string;
  parameters?: string;
}

interface IAuthToken {
  access_token: string;
  expires_in: number;
  "not-before-policy": number;
  refresh_expires_in: number;
  scope: string;
  token_type: string;
}

interface IPlaceOrderResponse {
  reference: string;
  orderId: string;
  checkoutUrl: string;
}

declare global {
  interface Window {
    openPay99Popup: () => void;
    onPay99: () => void;
  }
}

const arabTheme = createTheme({
  ...theme,
  direction: "rtl",
  typography: { ...theme.typography, fontFamily: "Cairo,Roboto" },
});
for (const prop in arabTheme.typography) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (Object.keys(arabTheme.typography[prop]).includes("fontFamily")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    arabTheme.typography[prop].fontFamily = "Cairo";
  }
}

const App: React.FC<IAppProps> = ({ language, inject_meta_tags, default_on_pay_handler, parameters = "" }) => {
  const [isDialogOpen, closeDialog, openDialog] = useDialog();
  const { setLanguage } = useTranslation();
  const [chosenTheme, setChosenTheme] = useState(theme);
  const requestAttempts = useRef(0);
  window.openPay99Popup = () => {
    openDialog();
  };

  const handleButtonClick = () => {
    openDialog();
  };

  const merchantCheckoutReq = (authConfigs: IAuthToken): Promise<unknown> => {
    let updatedParameters = parameters;

    if (window.REACT_APP_IS_DEMO_AVAILABLE === "true") {
      try {
        updatedParameters = JSON.stringify({ ...JSON.parse(parameters), reference: `DEMO_${Math.random()}` });
      } catch (e) {
        return Promise.reject(e);
      }
    }

    return fetch(`${window.REACT_APP_API_URL}/api/v1/order/merchant/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authConfigs.access_token}`,
      },
      body: updatedParameters,
    })
      .then((response) => {
        if (response.status !== 201) {
          return Promise.resolve(response.json()).then((parsedData: unknown) => Promise.reject(parsedData));
        }

        return response;
      })
      .then((response) => response.json())
      .then((data: IPlaceOrderResponse) => {
        window.location.href = `${data.checkoutUrl}`;
      })
      .catch((error) => {
        requestAttempts.current++;

        console.error("Error:", error);
        console.log("Next attempt", requestAttempts.current + 1);

        if (requestAttempts.current < 3) {
          return merchantCheckoutReq(authConfigs);
        } else {
          console.log("Retry attempts are over (not more than 3)");
        }
      });
  };

  const handleOnPay = () => {
    if (!parameters) {
      console.log("No parameters provided");
      return;
    }

    const tokenRequestURL = `${window.REACT_APP_API_URL}/api/v1/connect/merchant/auth`;
    fetch(tokenRequestURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        client_id: window.MERCHANT_CLIENT_ID,
        client_secret: window.MERCHANT_CLIENT_SECRET,
      }),
    })
      .then((response) => response.json())
      .then(merchantCheckoutReq)
      .catch((e) => {
        console.log(e);
        console.log("Need do request again");
      });
  };

  const onAgreeClick = default_on_pay_handler === "true" ? handleOnPay : window.onPay99;

  useEffect(() => {
    setLanguage(language);

    // updateDocumentOnLanguageChange(language)
    setChosenTheme(language === "en" ? theme : arabTheme);
  }, [language]);

  return (
    <ThemeProvider theme={chosenTheme}>
      {inject_meta_tags === "true" && (
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>
      )}
      <>
        <Pay99Button onButtonClick={handleButtonClick} />
        <Dialog open={isDialogOpen} onClose={closeDialog} onAgreeClick={onAgreeClick} />
        <p>© 2022 All Rights Reserved.</p>
      </>
    </ThemeProvider>
  );
};

export default App;
