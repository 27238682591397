import { Checkbox, CheckboxProps, darken, styled } from "@mui/material";

import CheckboxTick from "../../assets/icons/checkbox_tick.svg";
import { COLORS } from "../../constants/enums/COLORS";

const CheckboxIcon = styled("span")(({ theme }) => ({
  borderRadius: "4px",
  width: 20,
  height: 20,
  boxShadow: `inset 0px 0px 0px 2px ${theme.palette.greyscale.grey32 || COLORS.GREY_32}`,
  backgroundColor: theme.palette?.greyscale?.white || COLORS.WHITE,
  "input:hover ~ &": {
    backgroundColor: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  },
  "input:disabled ~ &": {
    boxShadow: `inset 0px 0px 0px 2px ${theme.palette.greyscale.grey08 || COLORS.GREY_08}`,
    opacity: 0.7,
  },
}));

const CheckboxCheckedIcon = styled(CheckboxIcon)(({ theme }) => ({
  backgroundColor: theme?.palette?.secondary?.main || COLORS.SECONDARY_MAIN,
  boxShadow: "unset",
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage: `url(${CheckboxTick})`,
    backgroundPosition: "1px 4px",
    backgroundRepeat: "no-repeat",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: darken(theme?.palette?.secondary?.main || COLORS.SECONDARY_MAIN, 0.1),
  },
}));

export const CustomCheckbox: React.FC<CheckboxProps> = (props) => {
  return (
    <Checkbox disableRipple color="default" checkedIcon={<CheckboxCheckedIcon />} icon={<CheckboxIcon />} {...props} />
  );
};
