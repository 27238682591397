export const TRANSLATIONS_ARAB = {
  SAR: "ريال",
  payLaterWith99: `ادفع بـ Jak`,
  payFixed: "ادفع",
  fixed: "ثابت",
  fixed99SAR: " قسط مشترياتك بدفعات ثابته بقيمه ٩٩٫٠٠ ريال شهريا ",
  fixed199SAR: "ﺷﻬﺮﻳﺎ ﻗﺴﻂ ﻣﺸﺘﺮﻳﺎﺗﻚ ﺑﺪﻓﻌﺎت ﺛﺎﺑﺘﻪ ﺑﻘﻴﻤﻪ ١٩٩٫٠٠ رﻳﺎل",
  fixed299SAR: "ﺷﻬﺮﻳﺎ ﻗﺴﻂ ﻣﺸﺘﺮﻳﺎﺗﻚ ﺑﺪﻓﻌﺎت ﺛﺎﺑﺘﻪ ﺑﻘﻴﻤﻪ ٢٩٩٫٠٠ رﻳﺎل",
  split3to6: "قسط مشترياتك على ٣ الى ٦ دفعات متساويه",
  ourPrograms: "عروضنا من اجلك",
  buyNowPayLater: "اشتري الان وادفع لاحقا",
  getTheOffer: `احصل على العرض`,
  goBack: `رجوع`,
  comingSoon: "قريباً",
  notAvailable: "ﻏﻴﺮ ﻣﺘﺎح",
};
