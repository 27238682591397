import { Box, styled } from "@mui/material";
import { FC } from "react";

import { Typography } from "../../Typography/Typography";

interface IPerMonthsHeader {
  currency?: string;
  cost?: string;
  period?: string;
  isArabic?: boolean;
}

const CostContainer = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  flexDirection: theme.direction === "rtl" ? "row-reverse" : "row",
  "& span:last-child": {
    paddingLeft: theme.direction === "rtl" ? "0" : "4px",
    paddingRight: theme.direction === "rtl" ? "4px" : "0",
  },
}));

const HeaderContainer = styled(Box)(() => ({
  "& > span": {
    display: "inline-block",
  },
}));

const PeriodText = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.direction === "rtl" ? "0" : "4px",
  paddingRight: theme.direction === "rtl" ? "4px" : "0",
}));

export const PerMonthHeader: FC<IPerMonthsHeader> = ({ currency = "", cost = "", period = "", isArabic = false }) => {
  return isArabic ? (
    <HeaderContainer>
      <CostContainer component="span">
        <Typography variant="p1" component="span" color="inherit" fontWeight="bold" lineHeight="1.8rem">
          {cost}
        </Typography>
        <Typography variant="p1" color="inherit">
          {currency}
        </Typography>
      </CostContainer>
      <PeriodText variant="p3" component="span" color="inherit">
        {period}
      </PeriodText>
    </HeaderContainer>
  ) : (
    <HeaderContainer>
      <CostContainer component="span">
        <Typography variant="p3" color="inherit">
          {currency}
        </Typography>
        <Typography variant="p1" component="span" color="inherit" fontWeight="bold" lineHeight="1.8rem">
          {cost}
        </Typography>
      </CostContainer>
      <PeriodText variant="p3" component="span" color="inherit">
        {period}
      </PeriodText>
    </HeaderContainer>
  );
};
