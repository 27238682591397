import { ButtonProps } from "@mui/material/Button/Button";
import MUIDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import styled from "@mui/material/styles/styled";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Button } from "../../ui-kit/src/components/Button";
import { Typography } from "../../ui-kit/src/components/Typography";
import { convertDigitsToArabic } from "../../utils/helpers/digitsToArabic";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { POSSIBLE_OFFERS } from "./Dialog.constants";
import { PossiblePayment } from "./PosiblePayment/PossiblePayment";

interface IDialogProps {
  open: boolean;
  onClose: () => void;
  onAgreeClick: () => void;
}

const StyledDialog = styled(MUIDialog)(({ theme }) => ({
  boxSizing: "border-box",
  fontFamily: "Brother-1816",
  fontSize: "16px",
  ".MuiBackdrop-root": {
    backgroundColor: "rgba(107, 114, 124, 0.24)",
  },
  "& .MuiPaper-root": {
    boxShadow: "0 12px 24px rgba(44, 41, 38, 0.02)",
    borderRadius: "16px",
    width: "100%",
    maxWidth: "789px",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDialog-container.MuiDialog-scrollPaper": {
      alignItems: "flex-end",
    },
    "& .MuiPaper-root": {
      margin: 0,
      overflowX: "hidden",
      maxHeight: "100%",
      borderBottomRightRadius: "0",
      borderBottomLeftRadius: "0",
    },
    "& .MuiDialogContent-root": {
      padding: "24px",
      minWidth: "unset",
    },
  },
}));

const DialogWrapper = styled(DialogContent)`
  padding: 40px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface IStyledButton extends ButtonProps {
  withMarginRight?: boolean;
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "withMarginRight",
})<IStyledButton>(({ theme, withMarginRight }) => ({
  minWidth: "156px",
  maxWidth: "327px",
  marginRight: withMarginRight ? "40px" : "0px",
  fontWeight: theme.direction === "rtl" ? "600" : "bold",
  [theme.breakpoints.up("sm")]: {
    width: "220px",
  },
}));

const CloseIcon = styled("button")`
  background: url(${window.REACT_APP_ASSETS_SRC + "/close_icon.svg"});
  width: 16px;
  height: 16px;
  background-size: cover;
  position: absolute;
  top: 28px;
  right: 48px;
  border: unset;
  :hover {
    cursor: pointer;
  }
`;

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: theme.direction === "rtl" ? "600" : "bold",
}));

const Logo = styled("img")`
  margin: 64px 0 24px;
  @media (max-width: 600px) {
    margin: 24px 0;
  }
`;

const ContentContainer = styled("div")(() => ({
  display: "flex",
  width: "100%",
  flexWrap: "wrap",
  justifyContent: "space-between",
  ["@media (max-width:750px)"]: {
    justifyContent: "center",
  },
}));

export const Dialog: React.FC<IDialogProps> = ({ open, onClose, onAgreeClick }) => {
  const { t, chosenLang } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const onPayButtonClick = () => {
    onAgreeClick();
    onClose();
  };

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth>
      <DialogWrapper>
        {!isMobile && <CloseIcon onClick={onClose} />}
        <Logo src={`${window.REACT_APP_ASSETS_SRC}/jak_logo.svg`} alt={"Jak logo"} />
        <Header fontSize={isMobile ? "20px" : "24px"} sx={{ marginBottom: "24px" }}>
          {t("ourPrograms")}
        </Header>
        <ContentContainer>
          {POSSIBLE_OFFERS.map((offer) => (
            <PossiblePayment
              key={t(offer.label)}
              headerText={t(offer.headerText)}
              headerCurrency={t(offer.headerCurrency || "")}
              label={t(offer.label)}
              headerCost={convertDigitsToArabic(offer.headerCost || "", chosenLang === "ar")}
              iconText={convertDigitsToArabic(offer.iconText, chosenLang === "ar")}
            />
          ))}
        </ContentContainer>

        <Grid
          container
          width="100%"
          justifyContent="space-between"
          marginTop={{ xs: "40px", sm: "24px" }}
        >
          <Grid item>
            <StyledButton
              variant="contained"
              color="secondary"
              sx={{ fontSize: "16px", padding: isMobile ? "14px 24px" : undefined }}
              onClick={onClose}
            >
              {t("goBack") as string}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="contained"
              sx={{ fontSize: "16px", padding: isMobile ? "14px 24px" : undefined }}
              onClick={onPayButtonClick}
            >
              {t("getTheOffer") as string}
            </StyledButton>
          </Grid>
        </Grid>
      </DialogWrapper>
    </StyledDialog>
  );
};
