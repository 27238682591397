import { Box } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { FC } from "react";

import { Typography } from "../../../ui-kit/src/components/Typography";
import { COLORS } from "../../../ui-kit/src/constants/enums/COLORS";
import { Banner } from "../../../ui-kit/src/exports";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { CostIcon } from "./CostIcon";

const PaymentContainer = styled(Box)<{ isDisable: boolean }>(({ theme, isDisable }) => ({
  backgroundColor: isDisable ? COLORS.GREY_04 : "",
  border: "2px solid #EDEEEF",
  borderRadius: "10px",
  padding: "13px",
  display: "flex",
  marginBottom: "34px",
  width: "297px",
  [theme.breakpoints.down("md")]: {
    width: "274px",
    marginBottom: "16px",
  },
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  marginLeft: theme.direction === "ltr" ? "18px" : "",
  marginRight: theme.direction === "rtl" ? "18px" : "",
}));

interface IPossiblePaymentProps {
  headerText: string;
  label: string;
  iconText: string;
  headerCurrency?: string;
  headerCost?: string;
}

export const PossiblePayment: FC<IPossiblePaymentProps> = ({
  headerText,
  label,
  iconText,
  headerCurrency,
  headerCost,
}) => {
  const theme = useTheme();
  const { t, chosenLang } = useTranslation();

  const isDisable = label === t("split3to6");

  return (
    <PaymentContainer isDisable={isDisable}>
      <CostIcon text={iconText} />
      <ContentContainer ml="18px">
        <Box mb="6px">
          <Typography component="span" fontSize="16px" fontWeight="bold">
            {headerText}{" "}
          </Typography>
          {headerCost && (
            <Typography component="span" fontSize="16px" fontWeight="bold">
              {headerCost}{" "}
            </Typography>
          )}

          {headerCurrency && (
            <Typography component="span" fontSize="16px">
              {headerCurrency}{" "}
            </Typography>
          )}
          {isDisable == false && chosenLang == "ar" ? (
            <Typography component="span" fontSize="16px">
              {t("fixed")}
            </Typography>
          ) : (
            ""
          )}
        </Box>
        <Box>
          <Typography fontSize="12px" color={theme.palette.greyscale.grey80 || COLORS.GREY_80}>
            {isDisable ? t("notAvailable") : label}
          </Typography>
        </Box>
        {isDisable && <Banner
          label={t("comingSoon")}
          lang={chosenLang}
          marginForEn={12}
          marginForArabic={30}
        />}
      </ContentContainer>
    </PaymentContainer>
  );
};
