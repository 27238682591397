import { FormControl, InputProps } from "@mui/material";
import React, { forwardRef, ReactNode, useCallback } from "react";

import { COLORS } from "../../../constants/enums/COLORS";
import { useConvertArabicToArabic } from "../../../hooks/useConvertArabicToArabic";
import { StyledFormHelperText, StyledInput, StyledLabel } from "../inputsStyles";
export interface IAbstractInputProps extends InputProps {
  label?: string;
  labelId?: string;
  errorText?: string;
  successText?: string;
  inputMessage?: ReactNode;
  hintText?: string;
  minWidth?: string;
  maxWidth?: string;
  render?: JSX.Element;
  showAdditionalMessage?: boolean;
  allowNumbersOnly?: boolean;
}

interface IAdditionalMessages {
  errorText: string | undefined;
  hintText: string | undefined;
  successText: string | undefined;
}

const chooseTextColor = ({ errorText, hintText, successText }: IAdditionalMessages): string => {
  switch (true) {
    case !!errorText:
      return COLORS.DARK_NEGATIVE;
    case !!successText:
      return COLORS.DARK_POSITIVE;
    case !!hintText:
    default:
      return COLORS.GREY_64;
  }
};

export const AbstractInput: React.FC<IAbstractInputProps> = forwardRef(
  (
    {
      label,
      labelId,
      errorText = "",
      successText = "",
      disabled,
      hintText = "",
      render,
      minWidth,
      maxWidth,
      showAdditionalMessage = true,
      allowNumbersOnly = false,
      onChange,
      ...props
    },
    ref,
  ) => {
    const { valueIsNumber, convertArabicToArabic } = useConvertArabicToArabic();

    const handleInputChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = event;
        const valueContainsOnlyNumbers = valueIsNumber(value);

        if (allowNumbersOnly && !valueContainsOnlyNumbers) {
          return;
        }

        const convertedValue = convertArabicToArabic(value);

        onChange && onChange({ ...event, target: { ...event.target, value: convertedValue } });
      },
      [allowNumbersOnly, onChange],
    );

    return (
      <FormControl fullWidth variant="standard" error={!!errorText} disabled={disabled}>
        {label && labelId && (
          <StyledLabel shrink={true} disableAnimation={true} htmlFor={labelId}>
            {label}
          </StyledLabel>
        )}
        <StyledInput
          fullWidth
          minWidth={minWidth}
          maxWidth={maxWidth}
          id={labelId}
          aria-describedby={labelId}
          disableUnderline={true}
          className={successText ? "success" : ""}
          ref={ref}
          {...props}
          onChange={handleInputChange}
        />
        {showAdditionalMessage && (
          <StyledFormHelperText
            sx={{
              color: chooseTextColor({ errorText, hintText, successText }),
            }}
          >
            {errorText || successText || hintText || ` `}
          </StyledFormHelperText>
        )}
        {render}
      </FormControl>
    );
  },
);

AbstractInput.displayName = "AbstractInput";
