export const TRANSLATIONS_EN = {
  SAR: "SAR",
  payFixed: "Pay fixed",
  payLaterWith99: "Pay later with Jak",
  fixed99SAR: "Split your payment into fixed SAR 99.00 monthly",
  fixed199SAR: "Split your payment into fixed SAR 199.00 monthly",
  fixed299SAR: "Split your payment into fixed SAR 299.00 monthly",
  split3to6: "Split your payment into 3 to 6 months without any interest.",
  ourPrograms: "Our Price plans",
  buyNowPayLater: "Buy now pay later",
  getTheOffer: "Get the offer",
  goBack: "Go back",
  comingSoon: "Coming soon",
  notAvailable: "Not available",
};
