import { styled, useTheme } from "@mui/material/styles";
import { FC } from "react";

import { Typography } from "../../../ui-kit/src/components/Typography";
import { COLORS } from "../../../ui-kit/src/constants/enums/COLORS";

const IconContainer = styled("div")(() => ({
  display: "flex",
  flexBasis: "40px",
  flexShrink: 0,
  width: "40px",
  height: "40px",
  background: "#ECEFF9",
  borderRadius: "20px",
  alignItems: "center",
  justifyContent: "center",
}));

export const CostIcon: FC<{ text: string }> = ({ text }) => {
  const theme = useTheme();

  return (
    <IconContainer>
      <Typography fontSize="16px" fontWeight="bold" color={theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY}>
        {text}
      </Typography>
    </IconContainer>
  );
};
