import { FormHelperText, FormLabel, InputLabel, styled } from "@mui/material";
import Input, { InputProps } from "@mui/material/Input";

import { COLORS } from "../../constants/enums/COLORS";

export const StyledLabel = styled(InputLabel)(({ theme }) => ({
  display: "flex",
  position: "relative",
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontSize: "1.2rem",
  fontWeight: theme.direction === "rtl" ? 600 : "bold",
  transform: "unset",
  marginBottom: "4px",
  color: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
  "&.Mui-focused": {
    color: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
  },
  "&.Mui-error": {
    color: COLORS.GREY_64,
  },
}));

export const StyledFormHelperText = styled(FormHelperText)(({ theme }) => ({
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: theme.direction === "rtl" ? 600 : "bold",
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  position: "relative",
  textAlign: "left",
  fontSize: "1.2rem",
  fontWeight: 400,
  transform: "unset",
  marginBottom: "4px",
  color: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
  "&.Mui-focused": {
    color: theme.palette?.secondary?.navy || COLORS.SECONDARY_NAVY,
  },
}));

interface StyledInputProps extends InputProps {
  minWidth?: string;
  maxWidth?: string;
}

export const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => !["minWidth", "maxWidth"].includes(prop as string),
})<StyledInputProps>(({ theme, minWidth = "327px", maxWidth = "376px" }) => ({
  background: theme.palette?.greyscale?.grey04 || COLORS.GREY_04,
  borderRadius: "8px",
  color: theme.palette?.primary?.dark || COLORS.PRIMARY_DARK,
  fontWeight: "bold",
  height: "56px",
  fontSize: "1.4rem",
  padding: "16px",
  minWidth,
  maxWidth,
  "&.Mui-focused": {
    boxShadow: `inset 0px -1px 0px ${theme.palette?.secondary?.main || COLORS.SECONDARY_MAIN}`,
  },
  "&.Mui-error": {
    boxShadow: `inset 0px -1px 0px ${theme.palette?.error?.main || COLORS.DARK_NEGATIVE}`,
  },
  "&.Mui-error.Mui-focused": {
    boxShadow: `inset 0px -1px 0px ${theme.palette?.error?.main || COLORS.DARK_NEGATIVE}`,
  },
  "&.success": {
    boxShadow: "unset",
  },
  "&.MuiInput-root": {
    marginTop: "0",
  },
  "& .MuiInput-input::placeholder": {
    fontWeight: theme.direction === "ltr" ? "bold" : "600",
  },
}));
