export const POSSIBLE_OFFERS = [
  {
    headerText: "payFixed",
    headerCurrency: "SAR",
    headerCost: "99.00",
    iconText: "99",
    label: "fixed99SAR",
  },
  {
    headerText: "payFixed",
    headerCurrency: "SAR",
    headerCost: "199.00",
    iconText: "199",
    label: "fixed199SAR",
  },
  {
    headerText: "payFixed",
    headerCurrency: "SAR",
    headerCost: "299.00",
    iconText: "299",
    label: "fixed299SAR",
  },
  {
    headerText: "buyNowPayLater",
    iconText: "3-6",
    label: "split3to6",
  },
];
