import { styled, Typography, useTheme } from "@mui/material";

import { COLORS } from "../../../constants/enums/COLORS";
import { IAbstractInputProps } from "../AbstractInput/AbstractInput";
import { BaseInput } from "../BaseInput";

interface ICurrencyInputProps extends IAbstractInputProps {
  currency: string;
}

const InputCurrencyLabel = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  marginRight: "12px",
  lineHeight: "24px",
  color: theme.palette?.greyscale?.grey64 || COLORS.GREY_64,
}));

export const CurrencyInput: React.FC<ICurrencyInputProps> = ({ label, labelId, currency, ...props }) => {
  const theme = useTheme();
  return (
    <>
      <BaseInput
        label={label}
        labelId={labelId}
        startAdornment={theme.direction === "ltr" ? <InputCurrencyLabel>{currency}</InputCurrencyLabel> : undefined}
        endAdornment={theme.direction === "rtl" ? <InputCurrencyLabel>{currency}</InputCurrencyLabel> : undefined}
        {...props}
      />
    </>
  );
};
