import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import { languageType } from "@translations/resources";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const widgetDivs = document.querySelectorAll(".quara-widget");

window.REACT_APP_API_URL = process.env.REACT_APP_API_URL || "https://api.jak.finance";
window.REACT_APP_APP_URL = process.env.REACT_APP_APP_URL || "https://checkout.jak.finance";
window.REACT_APP_ASSETS_SRC =
  process.env.REACT_APP_ASSETS_SRC ||
  "https://objectstorage.me-jeddah-1.oraclecloud.com/n/axci4wuwpelt/b/widget_static_dev/o";
window.REACT_APP_IS_DEMO_AVAILABLE = process.env.REACT_APP_IS_DEMO_AVAILABLE || "false";
window.MERCHANT_CLIENT_ID = "nike";
window.MERCHANT_CLIENT_SECRET = "73c0a48a-781a-43e9-bc05-237dfe0414ab";

// eslint-disable-next-line
const renderDiv = (div: any) => {
  ReactDOM.render(
    <React.StrictMode>
      <App
        language={((div as HTMLDivElement)?.dataset?.language as languageType) || "en"}
        inject_meta_tags={(div as HTMLDivElement)?.dataset?.inject_meta_tags}
        default_on_pay_handler={(div as HTMLDivElement)?.dataset?.default_on_pay_handler}
        parameters={(div as HTMLDivElement)?.dataset?.parameters}
      />
    </React.StrictMode>,
    div,
  );
};

widgetDivs.forEach((div) => {
  const config = {
    attributes: true,
  };

  // eslint-disable-next-line
  const callback = function (mutationsList: any) {
    for (const mutation of mutationsList) {
      if (mutation.type === "attributes") {
        renderDiv(div);
      }
    }
  };

  const observer = new MutationObserver(callback);

  observer.observe(div, config);

  renderDiv(div);

  // observer.disconnect();
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
