import { FormControl, Grid, styled, StyledComponentProps } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import { ReactComponent as MinusIcon } from "../../../assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { COLORS } from "../../../constants/enums/COLORS";
import { CustomIconButton } from "../../CustomIconButton";
import { IAbstractInputProps } from "../AbstractInput/AbstractInput";
import { BaseInput } from "../BaseInput";
import { StyledLabel } from "../inputsStyles";

interface IQuantityInputProps extends IAbstractInputProps {
  defaultValue?: number;
  onValueChange(val: number): void;
  maxValue?: number;
  numbersType?: "en" | "ar";
}

interface IQuantityInputWrapperProps {
  disabled?: boolean;
}

interface IStyledSignIconProps extends StyledComponentProps {
  disabled: boolean;
}

const QuantityInputWrapper = styled("div")<IQuantityInputWrapperProps>(({ theme, disabled }) => ({
  ...(disabled && {
    "& .Mui-disabled": {
      color: theme.palette.greyscale.grey32 || COLORS.GREY_32,
      "-webkit-text-fill-color": theme.palette.greyscale.grey32 || COLORS.GREY_32,
    },
    "svg path": {
      stroke: theme.palette.greyscale.grey32 || COLORS.GREY_32,
    },
  }),
}));

const StyledPlusIcon = styled(PlusIcon, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<IStyledSignIconProps>(({ theme, disabled }) => ({
  width: "16px",
  "& path": {
    stroke: theme.palette.secondary.navy,
  },
  ...(disabled && {
    "& path": {
      stroke: theme.palette.greyscale.grey32,
    },
  }),
}));

const StyledMinusIcon = styled(MinusIcon, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<IStyledSignIconProps>(({ theme, disabled }) => ({
  width: "16px",
  "& path": {
    stroke: theme.palette.secondary.navy,
  },
  ...(disabled && {
    "& path": {
      stroke: theme.palette.greyscale.grey32,
    },
  }),
}));

const InputButtonsContainer = styled(Grid)(({ theme }) => ({
  flexDirection: theme.direction === "rtl" ? "row-reverse" : "row",
}));

export const convertDigitsToArabic = (text: string | number, needConvert = true): string => {
  if (typeof text === "number") text = text.toString();
  return needConvert ? text.replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d as unknown as number]) : text;
};

/**
 * Components store input value inside and after each value change it executes `onChangeValue` handler,
 * even after component initialization, so setting of initial state should be handled at outside component.
 */
export const QuantityInput: React.FC<IQuantityInputProps> = ({
  label,
  labelId,
  onValueChange,
  maxValue,
  defaultValue = 0,
  numbersType = "en",
  ...props
}) => {
  const [currentQuantity, setCurrentQuantity] = useState<number>(defaultValue);

  const handleIncrement = () => setCurrentQuantity((prevQuantity) => prevQuantity + 1);
  const handleDecrement = () => {
    if (currentQuantity > 0) setCurrentQuantity((prevQuantity) => prevQuantity - 1);
  };
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (maxValue && +e.target.value > maxValue) {
      return;
    }
    if (!isNaN(+e.target.value)) {
      setCurrentQuantity(+e.target.value);
    }
  };

  useEffect(() => {
    onValueChange(currentQuantity);
  }, [currentQuantity]);

  return (
    <QuantityInputWrapper disabled={props.disabled}>
      <FormControl fullWidth sx={{ minWidth: "327px", maxWidth: "376px" }}>
        <Grid container>
          <Grid item>
            {label && labelId && (
              <StyledLabel shrink={true} disableAnimation={true} htmlFor={labelId}>
                {label}
              </StyledLabel>
            )}
          </Grid>
          <InputButtonsContainer flexWrap="nowrap" container spacing={1} sx={{ width: "100%" }}>
            <Grid item>
              <CustomIconButton
                width="56px"
                height="56px"
                onClick={handleDecrement}
                disabled={currentQuantity === 0 || props.disabled}
              >
                <StyledMinusIcon disabled={currentQuantity === 0} />
              </CustomIconButton>
            </Grid>
            <Grid item flexGrow={1}>
              <BaseInput
                minWidth="199px"
                value={numbersType === "en" ? currentQuantity : convertDigitsToArabic(currentQuantity)}
                labelId={labelId}
                inputProps={{ style: { textAlign: "center" } }}
                onChange={handleInputChange}
                allowNumbersOnly={true}
                {...props}
              />
            </Grid>
            <Grid item>
              <CustomIconButton
                width="56px"
                height="56px"
                onClick={handleIncrement}
                disabled={(maxValue && currentQuantity >= maxValue) || props.disabled}
              >
                <StyledPlusIcon disabled={!!maxValue && currentQuantity >= maxValue} />
              </CustomIconButton>
            </Grid>
          </InputButtonsContainer>
        </Grid>
      </FormControl>
    </QuantityInputWrapper>
  );
};
